@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .loading {
  align-items: center;
  display: flex;
  justify-content: center;
} */

/* .nameInput {
  box-sizing: border-box;

  position: absolute;
  width: 400px;
  height: 40px;
  left: 440px;
  top: 415px;
  
  border: 1px solid #003BE5;
  border-radius: 100px;
} */

/* .enterButton {
  position: absolute;
  width: 400px;
  height: 40px;
  left: 440px;
  top: 464px;

  background: #003BE5;
  border-radius: 100px;
  color: white;
} */

/* .searchBtn {
  position: absolute;
  width: 114px;
  height: 40px;
  left: 913px;
  top: 97px;

} */

/* .body {
  position: relative;
  width: auto;
  height: 720px;
  
  background: linear-gradient(123.66deg, #003BE5 12.08%, #00D5E2 86.52%);
} */

/* .boxBranco {
  position: absolute;
width: 750px;
height: 450px;
left: 265px;
top: 135px;

background: #FFFFFF;
box-shadow: 0px 0px 26px rgba(68, 73, 85, 0.2);
border-radius: 20px;
} */

/* ::placeholder {
  position: absolute;
width: 134px;
height: 14px;
left: 572px;
top: 429px;

font-family: 'Epilogue';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 14px;
text-align: center;

color: #003BE5;
} */

/* .aside {
  height: 720px;
  width: 250px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  background: #FFFFFF;
  }

 .search {
  height: 16px;
  width: 77px;
  left: 84px;
  top: 277px;
  border-radius: nullpx;
  font-family: Epilogue;
font-size: 16px;
font-weight: 700;
line-height: 16px;
letter-spacing: 0.03em;
text-align: left;
background: #444955;
  } */

/* .favorites {
  position: absolute;
width: 75px;
height: 16px;
left: 84px;
top: 354px;

font-family: 'Epilogue';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
letter-spacing: 0.03em;
text-transform: capitalize;

color: #444955;
} */

/* .profile {
  position: absolute;
width: 42px;
height: 16px;
left: 84px;
top: 431px;

font-family: 'Epilogue';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
letter-spacing: 0.03em;
text-transform: capitalize;

color: #444955;
} */

/* .username {
  position: absolute;
width: 83px;
height: 16px;
left: 93px;
top: 664px;

font-family: 'Epilogue';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
letter-spacing: 0.03em;
text-transform: capitalize;

color: #444955;
} */
/* 
.albumNotFound {
  position: absolute;
width: 448px;
height: 31px;
left: 506px;
top: 458.04px;

font-family: 'Epilogue';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 31px;
/* identical to box height */

/* text-align: center; */

/* color: #C0C3C9; */
/* } */

/* .loading {
  position: absolute;
width: 321px;
height: 50px;
left: 570px;
top: 458.04px;

font-family: 'Epilogue';
font-style: normal;
font-weight: 400;
font-size: 48.9205px;
line-height: 50px;
text-align: center;

color: #C0C3C9;
}

.header {
  height: 720px;
  width: 100%;
  left: 250px;
  top: 0px;
  border-radius: 0px;
  background: linear-gradient(123.66deg, #003BE5 12.08%, #00D5E2 86.52%);

} */

/* .showArea {
  height: 486px;
  width: 1030px;
  left: 250px;
  top: 234px;
  border-radius: 0px;
  background: #EFF3F9;

} */